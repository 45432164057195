import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Grid, Margin, BlockRenderer } from "@components/atoms"
import { GridItem } from "@components/molecules"
import { Newsletter, TransitionMask } from "@components/organisms"
import { PageProps } from "@types"

const AboutPage = ({
  data: {
    sanityPage: { backgroundColor, gridItems, extraAboutFields },
  },
}: PageProps) => {
  if (!extraAboutFields) return <></>

  const { _rawContactInfo } = extraAboutFields
  return (
    <Main backgroundColor={backgroundColor?.hex ?? "#fef3d9"}>
      <Checkers />
      <ContactInfo>
        <BlockRenderer>{_rawContactInfo}</BlockRenderer>
      </ContactInfo>
      <Margin>
        <Grid>
          {gridItems
            .filter(p => !!p)
            .map(item => (
              <GridItem key={item._key} {...item} />
            ))}
        </Grid>
      </Margin>
      <Newsletter />
      <TransitionMask />
    </Main>
  )
}

const ContactInfo = styled.div`
  text-align: center;
  padding: 4.5vw 0 0 0;
  a {
    font-family: "kessler-displayregular", serif;
  }
`

const Checkers = styled.div`
  height: 12vw;
  width: 100%;
  background: url("/images/about_checks.svg");
  background-size: auto 12vw;
  background-repeat: repeat-x;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  @media only screen and (min-width: 768px) {
    height: 4vw;
    background-size: auto 4vw;
  }
`

const Main = styled.main<{ backgroundColor: string }>`
  position: relative;
  z-index: 2;
  background-color: ${props => props.backgroundColor};
`

export const query = graphql`
  query AboutPageQuery {
    sanityPage(slug: { current: { eq: "about" } }) {
      ...pageFields
      extraAboutFields {
        _rawContactInfo
      }
    }
  }
`

export default AboutPage
